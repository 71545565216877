module.exports = {
  title: 'Prothetik Zentrum',
  description: 'Wir sind Prothetik Zentrum',
  debugMode: false,
  // be sure to not add any / at the end of URL
  url: {
    dev: 'http://localhost:3333',
    prod: 'https://www.protethikzentrum.ch/'
  },
  api: {
    projectId: 'cl7a274u',
    // dataset: process.env.NODE_ENV === 'production' ? 'production' : 'develop',
    dataset: 'production',
    // Add current date to fix the data api version. This avoids breaking changes over the time.
    apiVersion: '2021-10-11',
    useCdn: true
  },
  modules: {
    cookieConsent: {
      active: true,
      position: 'br', // tl = topleft, tr = topright, bl = bottomleft, br = bottomright
      transition: 'fb' // fd = fade, ft = fromTop, fl = fromLeft, fb = fromBottom, fr = fromRight
    }
  },
  mixins: {
    setDevmode: true,
    setViewportSizes: false
  },
  i18n: {
    baseUrl:
      process.env.NODE_ENV === 'production'
        ? 'https://www.prothetikzentrum.vercel.app/'
        : 'http://localhost:3333/',
    strategy: 'prefix_except_default',
    langDir: '~/locales/',
    vuex: {
      moduleName: 'i18n',
      syncRouteParams: true
    },
    locales: [
      {
        code: 'de',
        iso: 'de_CH',
        file: 'de.json'
      }
    ],
    defaultLocale: 'de',
    detectBrowserLanguage: {
      useCookie: true,
      cookieKey: 'i18n_redirected',
      fallbackLocale: 'de',
      redirectOn: 'root'
    }
  }
};
