import config from '../../starter.config';

export default {
  methods: {
    generatePageMetaTags(page) {
      if (!page || !page.seoMetadata) {
        return {
          htmlAttrs: {
            lang: config.i18n.defaultLocale
          }
        };
      }

      const {
        isHome,
        seoMetadata: {
          title = '',
          description = '',
          ogType = 'website',
          image = null,
          noIndex = false,
          noFollow = false
        },
        url,
        __i18n_lang,
        translations = undefined
      } = page;

      const prefetchTags = this.getPrefetchTags();
      const pageBaseUrl = this.getPageBaseUrl(isHome, url, __i18n_lang);
      const imagePath = this.getOgImagePath(image);
      const baseMetaTags = this.getBaseMetaTags(title, description, noIndex, noFollow);
      const linkTags = translations && this.getMultilanguageLinkTags(pageBaseUrl, translations);
      const schema = this.getPageSchema(pageBaseUrl, title, description);
      const socialMediaMetaTags = this.getSocialMediaMetaTags(
        pageBaseUrl,
        imagePath,
        title,
        description,
        ogType
      );

      return {
        htmlAttrs: {
          __i18n_lang
        },
        title,
        meta: [
          ...(prefetchTags || []),
          ...(baseMetaTags || []),
          ...(linkTags || []),
          ...(socialMediaMetaTags || [])
        ],
        script: [
          {
            type: 'application/ld+json',
            json: schema
          }
        ]
      };
    }
  }
};
