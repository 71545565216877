
import { mapGetters } from 'vuex';
import SVGLogoWhite from '../../assets/svg/logo-white.svg';

export default {
  name: 'FooterComponent',
  components: { SVGLogoWhite },
  data() {
    return {
      clicked: false
    };
  },
  computed: {
    ...mapGetters({
      isLoaderActive: 'ui/isLoaderActive',
      siteParts: 'settings/siteParts'
    })
  }
};
