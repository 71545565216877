
import SVGarrow from '../../assets/svg/arrow.svg';
// @group elements
export default {
  components: {
    SVGarrow
  },
  props: {
    tag: {
      type: String,
      default: 'button'
    },
    to: {
      type: String,
      default: null
    },
    variant: {
      type: String,
      default: 'primary'
    },
    variantType: {
      type: String,
      default: 'normal'
    },
    target: {
      type: String,
      default: null
    },
    arrowRight: {
      type: Boolean,
      default: false
    },
    arrowLeft: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    buttonClass() {
      switch (this.variant) {
        case 'primary':
          switch (this.variantType) {
            case 'normal':
              return 'btn primary-normal';
            case 'color':
              return 'btn primary-color';
            default:
              break;
          }
          break;
        case 'secondary':
          switch (this.variantType) {
            case 'normal':
              return 'btn secondary-normal';
            case 'color':
              return 'btn secondary-color';
            default:
              break;
          }
          break;
        default:
          break;
      }
      return null;
    }
  }
};
