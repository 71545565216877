
// @group elements
export default {
  props: {
    to: {
      type: [String, Object],
      default: null
    },

    blank: {
      type: Boolean,
      default: null
    }
  },

  computed: {
    isExternal() {
      return (
        typeof this.to === 'string' &&
        this.to.match(
          // eslint-disable-next-line
          /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)|tel:\+\d+)$/
        )
      );
    }
  }
};
