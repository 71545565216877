
// @group document
export default {
  props: {
    error: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // If something turns bad and we don't know what just show 404 to the client
      pageError: {
        statusCode: this.error?.statusCode || 404,
        message: this.error?.message || 'Page not found'
      }
    };
  }
};
