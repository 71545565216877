import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> News
 */

export default function getNewsQuery() {
  return groq`*[_type=="news" &&
    !(_id in path("drafts.**"))] {
  ...
}`;
}
