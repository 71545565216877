/**
 * TODO: write description
 */
export default {
  methods: {
    getPageBaseUrl(isHome, url, __i18n_lang) {
      const baseUrl = this.$config.baseURL;
      const isDefaultLocale = this.$i18n.defaultLocale === __i18n_lang;

      // Note: for base Language, we have this workaround
      // to avoid having Url like 'http://localhost:3333//'
      const pageLocaleSlug = isDefaultLocale ? '' : `/${__i18n_lang}`;
      const pageSlug = isHome ? '' : '/' + (url?.fullPath || '');

      return `${baseUrl}${pageLocaleSlug}${pageSlug}`;
    }
  }
};
