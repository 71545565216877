
import { mapGetters } from 'vuex';
import SvgLogoGreen from '../../assets/svg/logo-green.svg';

export default {
  name: 'HeaderComponent',
  components: { SvgLogoGreen },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      siteParts: 'settings/siteParts'
    }),
    outlineButtonLink() {
      if (!this.siteParts.header) {
        return '';
      }
      return this.siteParts.header?.outlineButton?.isExternal
        ? this.siteParts.header?.outlineButton?.externalLink
        : this.getPageByRef(this.siteParts.header?.outlineButton?.link?._ref)?.url?.fullPath;
    },
    primaryButtonLink() {
      if (!this.siteParts.header) {
        return '';
      }
      return this.siteParts.header?.primaryButton?.isExternal
        ? this.siteParts.header?.primaryButton?.externalLink
        : this.getPageByRef(this.siteParts.header?.primaryButton?.link?._ref)?.url?.fullPath;
    }
  },

  methods: {
    toggleNavOverlay() {
      this.$store.commit('ui/toggleOverlay', !this.isOverlayActive);
    }
  }
};
