import getSiteSettingsQuery from '~/queries/getSiteSettingsQuery';
import getSiteNewsQuery from '~/queries/getNewsQuery';

export const state = () => ({
  legal: [],
  socialMedia: [],
  staticText: {},
  sitemap: [],
  siteParts: {},
  news: [],
  menus: [],
  locale: ''
});

export const mutations = {
  setStaticText(state, staticText) {
    state.staticText = staticText;
  },
  setSitemap(state, sitemap) {
    state.sitemap = sitemap;
  },
  setLegal(state, legal) {
    state.legal = legal;
  },
  setSocialMedia(state, socialMedia) {
    state.socialMedia = socialMedia;
  },
  setSiteParts(state, siteParts) {
    state.siteParts = siteParts;
  },
  setSiteNews(state, siteNews) {
    state.news = siteNews;
  },
  setMenus(state, menus) {
    state.menus = menus;
  },
  setLocale(state, locale) {
    state.locale = locale;
  }
};

export const getters = {
  siteParts(state) {
    return state.siteParts;
  },
  staticText(state) {
    return state.staticText;
  },
  sitemap(state) {
    return state.sitemap;
  },
  legal(state) {
    return state.legal;
  },
  news(state) {
    return state.news;
  },
  socialMedia(state) {
    return state.socialMedia;
  },
  getStaticTextByKey: (state) => (key) => {
    return state.staticText?.find(({ entry }) => entry.key === key);
  },
  getSlugByRef: (state) => (_ref) => {
    return state.sitemap.find((page) => page._id === _ref);
  },
  menus(state) {
    return state.menus;
  },
  locale(state) {
    return state.locale;
  },
  getMenuById: (state) => (menuId) => {
    return state.menus.find((menu) => menu.menuId === menuId);
  }
};

export const actions = {
  async load({ commit, rootState }) {
    const locale = rootState.navigation.locale;
    const query = getSiteSettingsQuery(locale);
    const queryNews = getSiteNewsQuery();
    const result = await this.$sanity.fetch(query);
    const news = await this.$sanity.fetch(queryNews);

    commit('setSitemap', result?.sitemap ?? []);
    commit('setSiteNews', news ?? []);
    commit('setStaticText', result?.staticText ?? []);
    commit('setLegal', result?.legal ?? []);
    commit('setSocialMedia', result?.socialMedia ?? []);
    commit('setSiteParts', result?.siteParts ?? {});
    commit('setMenus', result?.menus ?? []);
  }
};
