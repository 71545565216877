import { groq } from '@nuxtjs/sanity';

/**
 * With this query, we get all the content from the Sanity -> Settings
 */

export default function getSiteSettingsQuery(locale) {
  return groq`*[_type=="page" && __i18n_lang=="${locale}"][0] {
    "socialMedia":  *[_type=="socialMedia"][0],

    "sitemap": *[
      _type=="page" &&
      __i18n_lang=="${locale}" &&
      !(_id in path("drafts.**"))
    ] { url, _id, __i18n_lang, live },

    "legal": *[
      _type=="legal" &&
      __i18n_lang=="${locale}"
    ] { conditions->{slug}, imprint->{slug}, privacy->{slug} },

    "staticText": *[_type=="staticText" && __i18n_lang=="${locale}"] { entry, _id },

    "siteParts": *[_type=="siteParts" && __i18n_lang=="${locale}"][0] { ... },

    "menus": *[_type=="menu" && __i18n_lang=="${locale}"]
  }`;
}
