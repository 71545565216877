
import { mapGetters } from 'vuex';

export default {
  name: 'MenuOverlay',
  computed: {
    ...mapGetters({
      isOverlayActive: 'ui/isOverlayActive',
      siteParts: 'settings/siteParts'
    }),

    localePrefix() {
      return this.$i18n.locale === 'de' ? '' : `/${this.$i18n.locale}`;
    },

    primaryButtonLink() {
      if (!this.siteParts.header) {
        return '';
      }
      return this.siteParts.header?.primaryButton?.isExternal
        ? this.siteParts.header?.primaryButton?.externalLink
        : this.getPageByRef(this.siteParts.header?.primaryButton?.link?._ref)?.url?.fullPath;
    },
    outlineButtonLink() {
      if (!this.siteParts.header) {
        return '';
      }
      return this.siteParts.header?.outlineButton?.isExternal
        ? this.siteParts.header?.outlineButton?.externalLink
        : this.getPageByRef(this.siteParts.header?.outlineButton?.link?._ref)?.url?.fullPath;
    }
  },

  watch: {
    isOverlayActive() {
      document.documentElement.classList[this.isOverlayActive ? 'add' : 'remove']('no-scroll');
    },

    $route() {
      this.closeOverlay();
    }
  },

  beforeMount() {
    document.addEventListener('keyup', this.closeOverlayEsc);
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.closeOverlayEsc);
  },

  methods: {
    closeOverlay() {
      this.$store.commit('ui/toggleOverlay', false);
    },

    closeOverlayEsc(e) {
      if (this.active && e.keyCode === 27) {
        this.$store.commit('ui/toggleOverlay', false);
      }
    }
  }
};
